<template>
  <div style="background: linear-gradient(to top, #FFF, #F8F9FD);height: 97.6%;">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/alarmRules' }">告警规则</el-breadcrumb-item>
          <el-breadcrumb-item>规则详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="white-bg-card">
            <div class="form-title">基本信息</div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="规则名称：">{{form.title}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="告警级别：">
                  <el-radio-group v-model="form.level" style="width:100%;">
                    <el-radio :label="1" disabled>提示告警</el-radio>
                    <el-radio :label="2" disabled>普通告警</el-radio>
                    <el-radio :label="3" disabled>重要告警</el-radio>
                    <el-radio :label="4" disabled>严重告警</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="规则内容：" prop="content">{{form.content}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="通知方式：">
                  <div class="notify-method">
                    <el-checkbox-group v-model="form.notificationModeCodes">
                      <el-checkbox label="system" disabled>系统内通知</el-checkbox>
                      <el-checkbox label="sms" disabled>短信</el-checkbox>
                      <el-checkbox label="entWeChatRobot" disabled>企业微信</el-checkbox>
                      <span style="margin-left:30px;" v-if="form.alarmTypeCode=='equipment'||form.alarmTypeCode=='equipmentOffline'">
                        <el-checkbox label="voice" disabled>语音播报</el-checkbox>
                        <el-checkbox label="popup" disabled>组态内弹窗</el-checkbox>
                      </span>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="规则类型：" prop="alarmGroup">
                  {{form.alarmTypeCode=='operationTaskRuleExpiration'?'巡点检规则过期告警'
                  :form.alarmTypeCode=='lubeTaskRuleExpiration'?'润滑规则过期告警'
                  :form.alarmTypeCode=='equipment'?'设备类型'
                  :form.alarmTypeCode=='equipmentOffline'?'设备离线'
                  :'-'}}
                </el-form-item>
              </el-col>
              <div v-if="form.notificationModeCodes">
                <el-col :span="12" v-if="form.notificationModeCodes.find(item=>item=='entWeChatRobot')=='entWeChatRobot'">
                  <el-form-item label="机器人：">
                    <span>{{ form.entWeChatRobotName?form.entWeChatRobotName:'-' }}</span>
                  </el-form-item>
                </el-col>
              </div>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="通知人：">
                  <span v-if="form.receivers.length>0">
                    <span v-for="(item, index) in form.receivers" :key="index">
                      {{ form.receivers.length-1 > index ? item.staffName+'、' : item.staffName }}
                    </span>
                  </span>
                  <span v-else>-</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="white-bg-card" v-if="form.alarmTypeCode=='equipment'&&form.equipmentRun">
            <!-- 设备类型 -->
            <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
              <div class="form-title" style="margin-bottom:0;">设备类型</div>
              <div></div>
            </div>
            <!-- 循环设备Tag -->
            <div class="custom-attr" v-for="(dItem,dIndex) in form.equipmentRun.equipmentTrigger.equipments" :key="dIndex">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="设备名称：">{{ dItem.equipmentName }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="点表名称：">
                    <span v-if="dItem.triggerCondition.boolData&&dItem.triggerCondition.boolData.formulaType">{{ dItem.triggerCondition.boolData.tag.split(dItem.triggerCondition.boolData.formulaType)[0] }}</span>
                    <span v-if="dItem.triggerCondition.realData&&dItem.triggerCondition.realData.formulaType">{{ dItem.triggerCondition.realData.tag }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="数据类型：">{{dItem.triggerCondition.dataType}}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="运算类型：" v-if="dItem.triggerCondition.dataType=='Bool'&&dItem.triggerCondition.boolData">
                    {{dItem.triggerCondition.boolData.formulaType=='empty'?'无'
                    :dItem.triggerCondition.boolData.formulaType=='&'?'&'
                    :dItem.triggerCondition.boolData.formulaType=='||'?'||'
                    :dItem.triggerCondition.boolData.formulaType=='!'?'!'
                    :''}}
                  </el-form-item>
                  <el-form-item label="运算类型：" v-if="dItem.triggerCondition.dataType=='Real'&&dItem.triggerCondition.realData">
                    {{dItem.triggerCondition.realData.formulaType=='empty'?'无'
                    :dItem.triggerCondition.realData.formulaType=='gt'?'&gt;'
                    :dItem.triggerCondition.realData.formulaType=='ge'?'&gt;='
                    :dItem.triggerCondition.realData.formulaType=='lt'?'&lt;'
                    :dItem.triggerCondition.realData.formulaType=='le'?'&lt;='
                    :dItem.triggerCondition.realData.formulaType=='mid'?'&lt;&lt;(区间内运算)'
                    :dItem.triggerCondition.realData.formulaType=='side'?'&gt;&lt;(区间外运算)'
                    :''}}
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="dItem.triggerCondition.dataType=='Bool'">
                <div v-if="dItem.triggerCondition.boolData&&dItem.triggerCondition.boolData.formulaType">
                  <el-form-item label="运算公式：">
                    <div v-if="dItem.triggerCondition.boolData.formulaType=='empty'">无</div>
                    <div v-else>{{ dItem.triggerCondition.boolData.tag }}</div>
                  </el-form-item>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="单位：">
                        {{ dItem.triggerCondition.boolData.unit?dItem.triggerCondition.boolData.unit:'-' }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="描述：">
                        {{ dItem.triggerCondition.boolData.tagDescribe?dItem.triggerCondition.boolData.tagDescribe:'-' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 触发条件 -->
                  <div class="tag-explain">
                    <span class="tag-explain-title">触发条件</span>
                  </div>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="点表值：">
                        {{ dItem.triggerCondition.boolData.tagValue?dItem.triggerCondition.boolData.tagValue:'-' }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="点表值含义：">
                        {{ dItem.triggerCondition.boolData.tagValueDescribe?dItem.triggerCondition.boolData.tagValueDescribe:'-' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div v-if="dItem.triggerCondition.dataType=='Real'">
                  <div v-if="dItem.triggerCondition.realData&&dItem.triggerCondition.realData.formulaType">
                      <el-form-item label="运算公式：">
                          <div v-show="dItem.triggerCondition.realData.formulaType=='empty'">无</div>
                          <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='gt'">
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">&gt;</div>
                              <div>
                                  {{dItem.triggerCondition.realData.threshold}}
                              </div>
                          </div>

                          <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='ge'">
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">>=</div>
                              <div>
                                  {{dItem.triggerCondition.realData.threshold}}
                              </div>
                          </div>
                          <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='lt'">
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">&lt;</div>
                              <div>
                                  {{dItem.triggerCondition.realData.threshold}}
                              </div>
                          </div>
                          <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='le'">
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">&lt;=</div>
                              <div>
                                  {{dItem.triggerCondition.realData.threshold}}
                              </div>
                          </div>
                          <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='mid'">
                              <div>
                                  {{dItem.triggerCondition.realData.minThreshold}}
                              </div> 
                              <div class="f-symbol">&lt;</div>
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">&lt;</div>
                              <div>
                                  {{dItem.triggerCondition.realData.maxThreshold}}
                              </div>
                          </div>
                          <div class="formula-type" v-show="dItem.triggerCondition.realData.formulaType=='side'">
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">&gt;</div>
                              <div>
                                  {{dItem.triggerCondition.realData.maxThreshold}}
                              </div>
                              <div class="f-symbol">||</div>
                              <div class="formula-left">{{dItem.triggerCondition.realData.tag}}</div>
                              <div class="f-symbol">&lt;</div>
                              <div>
                                  {{dItem.triggerCondition.realData.minThreshold}}
                              </div>
                          </div>
                      </el-form-item>
                  </div>
              </div>
            </div>
          </div>
          <!-- 离线类型 -->
          <div class="white-bg-card" v-if="form.alarmTypeCode=='equipmentOffline'&&form.equipmentOffLine">
            <!-- 设备类型 -->
            <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
              <div class="form-title" style="margin-bottom:0;">设备类型</div>
              <div></div>
            </div>
            <div class="custom-attr" v-for="(dItem,dIndex) in form.equipmentOffLine.equipmentTrigger.equipments" :key="dIndex">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="设备名称：" style="margin-bottom: 0;">{{ dItem.equipmentName }}</el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/alarmRules')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        receivers: []
      },
      row: {},
      disabled: false,
    };
  },
  methods: {
    loadData() {
      this.loading = true
      this.$ajax.post('alarmRulesDetail',{},{},this.row.id).then(res => {
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/alarmRules");
    } else {
      this.row = this.$route.params.row
      this.loadData()
    }
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 0px 50px;
}
.el-input {
  width: 500px;
}
.el-textarea {
  width: 500px;
}
.el-radio {
  margin-right: 60px;
}
.notify-method {
  width: 650px;
  display: flex;
}
.custom-attr {
  border: 1px solid #ebeef5;
  // box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
}
.formula-type {
  display: flex;
  align-items: center;
}
.formula-left {
  background-color: #ecf5ff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  color: #409eff;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 14px;
}
.f-symbol {
  font-size: 14px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 5px;
  width: 30px;
  line-height: 30px;
  margin: 0 20px;
  color: #333;
}
.tag-explain {
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin:20px 0px;
}
.tag-explain-title {
  font-size:14px;
  margin-left:40px;
  font-weight:bold;
}
</style>